import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { createBlogLink, createExcerpt } from '../../utils';
import BlogPostMeta from './blog-post-meta';

function Hero(props) {
  const { image, title, excerpt, slug, date, author, tags } = props;

  return (
    <div className="blog-hero">
      <div className="blog-hero-image-container">
        <GatsbyImage image={getImage(image)} alt={title} objectFit="cover" />
        <div className="bg-blur" />
      </div>

      <div className="blog-hero-content">
        <div className="container">
          <BlogPostMeta date={date} author={author} tags={tags} className="text-dark" />
          <Link className="no-hover-link text-dark" to={createBlogLink(slug)}>
            <h2 className="text-mega text-dark text-hero mb-4">{title}</h2>
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: excerpt }} />
          </Link>
          <Link to={createBlogLink(slug)} className="btn btn-secondary">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  slug: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.object,
  tags: PropTypes.object,
};

export default Hero;
